// @flow
import React from 'react';
import { getContactHref } from '../../../utils';
import styles from './Author.module.scss';
import { useSiteMetadata } from '../../../hooks';

const Author = () => {
  const { author } = useSiteMetadata();

  return (
    <div className={styles['author']}>
      <p className={styles['author__bio']}>
        {/* {author.bio} */}
        <span>{author.name}</span>

        <span className={styles['author__bio-twitter']}>
          Follow me on
          <a
            className={styles['author__bio-twitter']}
            href={getContactHref('twitter', author.contacts.twitter)}
            rel="noopener noreferrer"
            target="_blank"
          >Twitter
          </a>
        </span>

      </p>
    </div>
  );
};

export default Author;
